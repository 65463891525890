.App {
  display: flex;
  flex-direction: column;
  background-color: #cdf488;
  align-items: stretch;
  justify-content: flex-start;
  align-items: center;
  height: 2000px;
  padding: 20px;
}

.construction {
  display: flex;
  flex-direction: column;
  background-color: #f48888;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding: 10px;
}

.navbaropen {
  background-color: #e7a138;
  height: 45px;
  display: flex;
  justify-content: center;
}

.navbarclosed {
  background-color: #e7a138;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.languages {
  display: flex;
  background-color: #e7a138;
}

.language {
  border-radius: 25px;
  height: 35px;
  width: 35px;
  border-width: 0px;
  background-color: #611a9f;
  color: white;
  margin: 5px;
}

.image {
  max-width: 100%;
  width: 500px;
}

.logo {
  /* max-width: 100%;
  width: 200px;
  padding: 50px;
  border-radius: 100px; */
  border-style: solid;
  border-color: #984eda;
  width: 250px;
  border-radius: 125px;
  height: 250px;
}

.icon {
  background-color: #e7a138;
  margin-left: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border-width: 0px;
  align-self: flex-start;
}

.options {
  background-color: #e7a138;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.branding {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main {
  background-color: #6290ec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 10vh;
}

.option {
  display: flex;
  margin-top: 20px;
  margin-left: 12px;
  font-size: large;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #28212e;
}

.logoback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  border-radius: 125px;
  height: 250px;
  background-color: #fff;
  margin-bottom: 30px;
}

.div1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: #100e0a;
  height: 85px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  /* padding: 10px; */
}

.textfooter {
  margin-left: 10px;
}

/*////////////////////////*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
